<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <img src="../../assets/images/logo/logo-green.png"/>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5" style="background-color:#f0f9f5 ;">
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center px-5"
                >
                    <b-img fluid style="width: 70%;" :src="imgUrl" alt="Login V2"/>
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col lg="4" class="row auth-bg px-1 p-2 pt-3" style="border-left: solid 1px #182c25">
                <div class="col-md-12 pr-0 languages-mobile" align="end">
                    <b-dropdown
                        text="Dark"
                        variant="outline-dark"
                        id="dropdown-grouped"
                        class="dropdown-language no-border-button"
                        right
                    >
                        <template #button-content>
                            <b-img
                                :src="currentLocale.img"
                                height="14px"
                                width="22px"
                                :alt="currentLocale.locale"
                            />
                            <span class="ml-50 text-body">{{ $t(currentLocale.name) }}</span>
                        </template>
                        <b-dropdown-item
                            v-for="localeObj in locales"
                            :key="localeObj.locale"
                            @click="$i18n.locale = localeObj.locale"
                        >
                            <b-img
                                :src="localeObj.img"
                                height="14px"
                                width="22px"
                                :alt="localeObj.locale"
                            />
                            <span class="ml-50">{{ $t(localeObj.name) }}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <b-col sm="12" md="12" lg="12" class="px-xl-2 mx-auto mt-2">
                    <h4 class="row justify-content-between">
                        <b-card-title
                            title-tag="h2"
                            class="font-weight-bold mb-2 col-md-12"
                        >
                            {{ $t("verify_your_account") }}
                        </b-card-title>
                    </h4>

                    <b-card-text class="mb-2" v-if="verify_type === 'email'">
                        {{ $t("please_checkout_your_email_for_the_verification_pin_number") }}
                    </b-card-text>
                    <b-card-text class="mb-2" v-else>
                        {{ $t("please_checkout_your_phone_for_the_verification_pin_number") }}
                    </b-card-text>

                    <b-card-text class="mb-2">
                        {{ phone_email }}
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="verificationValidation">
                        <b-form class="auth-login-form mt-2" @submit.prevent>
                            <!-- pin -->
                            <b-form-group label="PIN" label-for="pin-number">
                                <validation-provider
                                    #default="{ errors }"
                                    name="PIN"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="pin-number"
                                        v-model.trim="pinNumber"
                                        :state="errors.length > 0 ? false : null"
                                        name="pin-number"
                                        placeholder="1234"
                                    />
                                    <small v-if="pinNumberError" class="text-danger">{{ pinNumberErrorText }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                @click="validationForm"
                            >
                                {{ $t("verify") }}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <b-card-text class="text-center mt-2">
                        <b-card-text v-if="!resent" role="button" @click="resend()">
                            <span>{{ $t("resend_code") }}</span>
                        </b-card-text>
                        <b-card-text v-else>
                          <span>
                            {{ countdown }} {{ $t("resend_verification_code") }}
                          </span>
                        </b-card-text>
                    </b-card-text>

                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
  /* eslint-disable global-require */
  import {ValidationProvider, ValidationObserver} from "vee-validate";
  import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
  } from "bootstrap-vue";
  import {required, email} from "@validations";
  import {togglePasswordVisibility} from "@core/mixins/ui/forms";
  import store from "@/store";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

  export default {
    components: {
      BRow,
      BCol,
      BLink,
      BFormGroup,
      BFormInput,
      BCardText,
      BCardTitle,
      BImg,
      BForm,
      BButton,
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        resent: false,
        countdown: 60,
        pinNumber: "",
        pinNumberError: false,
        pinNumberErrorText: null,
        userId: this.$store.state.auth.user.id,
        sideImg: require("@/assets/images/pages/login-v5.png"),
        locales: [
          {
            locale: "en",
            img: "/images/flags/en.png",
            name: "english",
          },
          {
            locale: "de",
            img: "/images/flags/german.png",
            name: "german",
          },
        ],
        language: "",

        phone_email: null,
        verify_type: null,
        finish_phone_email: null,
      };

    },

    created() {
      this.startCountdown();
      this.getTwoFactorInfo()
    },

    computed: {
      imgUrl() {
        if (store.state.appConfig.layout.skin === "dark") {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
          return this.sideImg;
        }
        return this.sideImg;
      },
      currentLocale() {
        let obj = null;
        if (this.$i18n.locale) {
          if (this.$i18n.locale == "en") {
            obj = {
              locale: "en",
              img: "/images/flags/en.png",
              name: "english",
            };
          } else {
            obj = {
              locale: "de",
              img: "/images/flags/german.png",
              name: "german",
            };
          }
        } else {
          obj = {
            locale: "en",
            img: "/images/flags/en.png",
            name: "english",
          };
        }
        this.$store.commit("app/UPDATE_LANGUAGE", obj.locale);
        return obj;
      },
    },
    methods: {
      getTwoFactorInfo() {
        this.$http
            .post(`check-user-two-factor/${this.$store.state.auth.user.id}`, {
              "send_code": true
            })
            .then((res) => {
              if (res.data.value.bool) {
                this.phone_email = res.data.value.twoFactorData.phone_email;
                this.verify_type = res.data.value.twoFactorData.type;
                console.log(this.phone_email)
                // this.changePhoneEmailFormat(this.phone_email);
              }
            });
      },

      toggleLanguage(language) {
        this.$i18n.locale = language;
        this.language = language;
        this.$store.commit("app/UPDATE_LANGUAGE", language);
      },

      validationForm() {
        this.$http
            .post(`/two-factor-code-verify`, {
              'code': this.pinNumber,
              'user_id': this.userId
            })
            .then((res) => {
              if (res) {
                if (res.data.status === 200) {
                  this.$store.commit("auth/SET_AUTHENTICATED", true);
                  this.$store.commit("auth/SET_USER", this.$store.state.auth.user);
                  this.$store.commit("auth/UPDATE_TOKEN", this.$store.state.auth.user.token);
                  this.$store.commit("auth/SHOW_ALERT", true);
                  const params = new URLSearchParams(window.location.search);
                  if (params.has('source')) {
                    if (params.has('id')) {
                      window.location.href = "/" + params.get('source') + '/' + params.get('id');
                    } else {
                      window.location.href = "/" + params.get('source');
                    }
                  } else if(params.has('form')) {
                     window.location.href = "/cookpit/task/fill-online-form";
                  } else {
                    window.location.href = "/";
                  }
                } else if (res.data.status === 422) {
                  this.pinNumberErrorText = res.data.message;
                  this.pinNumberError = true;
                }
              }
            });
      },

      resend() {
        this.$http
            .post(`check-user-two-factor/${this.$store.state.auth.user.id}`)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("verification_code_sent_successfully"),
                  icon: "SuccessIcon",
                  variant: "success",
                },
              });
              this.startCountdown();

            })

      },

      startCountdown() {
        this.resent = true;
        let timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(timer);
            this.resent = false;
            this.countdown = 60;
          }
        }, 1000);
      }
    },
  };
</script>

<style lang="scss">
    @import "@core/scss/vue/pages/page-auth.scss";
</style>
